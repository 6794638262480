import { useTranslation } from "../helpers/useTranslation";
import * as yup from "yup";

export const useFormValidation = () => {
  const { get } = useTranslation();

  const ebvdDocumentValidationSchema = yup
    .object({
      ChangeDescription: yup.string().when("VersionNumber", {
        is: (VersionNumber: number) => VersionNumber > 1,
        then: yup
          .string()
          .trim()
          .required(get("fieldDocDataChangeDesc") + " " + get("errorRequiredMessage")),
        otherwise: yup.string(),
      }),
      //ArticleInformation
      DocumentProductGroupSystems: yup
        .array()
        .min(1, get("articleProductGroup") + " : " + get("errorMinimumOneMessage"))
        .required(get("articleProductGroup") + " : " + get("errorMinimumOneMessage"))
        .nullable(),
      DocumentProductSystems: yup
        .array()
        .min(1, get("articleConceptTitle") + " : " + get("errorMinimumOneMessage"))
        .required(get("articleConceptTitle") + " : " + get("errorMinimumOneMessage"))
        .nullable(),

      NoGTINComment: yup.string().when("DocumentProductSystems", {
        is: (DocumentProductSystems: DocumentProductSystem[]) =>
          DocumentProductSystems !== null &&
          DocumentProductSystems.some((x: DocumentProductSystem) => x.ProductSystemId === "GTIN"),
        then: yup.string(),
        otherwise: yup
          .string()
          .trim()
          .required(get("articleGTINTitle") + " : " + get("errorRequiredMessage")),
      }),
      Product: yup.object().shape({
        Description: yup.string().required(get("articleDescription") + " : " + get("errorRequiredMessage")),
        DeclarationOfPerformance: yup.number().typeError(get("errorRequiredMessage")),
        DeclarationOfPerformanceNumber: yup.string().when("DeclarationOfPerformance", {
          is: (DeclarationOfPerformance: number) => DeclarationOfPerformance === 1,
          then: yup
            .string()
            .trim()
            .required(get("articleDeclarationNumber") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string(),
        }),
      }),

      //Chemical Content
      ProductContent: yup.object().shape({
        DeclaredWeightPercentage: yup
          .number()
          .typeError(get("chemicalContentDeclaredWeightPercentage") + " : " + get("errorRequiredMessage")),
        DeclaredWeightComment: yup.string().when("DeclaredWeightPercentage", {
          is: (DeclaredWeightPercentage: number) => DeclaredWeightPercentage <= 99,
          then: yup
            .string()
            .trim()
            .required(get("chemicalContentDeclaredWeightComment") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string().nullable(),
        }),
        HasProductClassification: yup
          .string()
          .required(get("chemicalContentHasProductClassification") + " : " + get("errorRequiredMessage")),
        ProductClassification: yup.string().when("HasProductClassification", {
          is: (HasProductClassification: string) => HasProductClassification === "1",
          then: yup
            .string()
            .trim()
            .required(get("chemicalContentProductClassification") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string().nullable(),
        }),
        ProductContentTypeId: yup
          .number()
          .required(get("chemicalContentProductContentTypeId") + " : " + get("errorRequiredMessage"))
          .nullable(),
        HasSafetyDatasheet: yup
          .string()
          .required(get("chemicalContentHasSafetyDatasheet") + " : " + get("errorRequiredMessage")),
        CandidateListEdition: yup
          .string()
          .required(get("chemicalContentCandidateListEdition") + " : " + get("errorRequiredMessage")),
        RoHS: yup.string().required(get("chemicalContentRoHS") + " : " + get("errorRequiredMessage")),
      }),
      ProductContentComponents: yup
        .array()
        .min(1)
        .required(get("chemicalContentArticle") + " : " + get("errorRequiredMessage"))
        .nullable(),

      //RawMaterial
      ProductRawMaterial: yup.object().shape({
        HasRecycledMaterial: yup
          .boolean()
          .required(get("totalRecycleMaterialArticle") + " : " + get("errorRequiredMessage")),
        HasRawMaterialOriginFossil: yup.boolean().nullable(),
        RawMaterialOriginFossilPart: yup
          .number()
          .nullable()
          .default(0)
          .when("HasRawMaterialOriginFossil", {
            is: true,
            then: yup.number().required(get("hasRawMaterialOriginFossil") + " : " + get("errorRequiredMessage")),
            otherwise: yup.number().nullable(),
          })
          .nullable(),
        WoodCertified: yup.boolean().required(),
        WoodCertifiedSystem: yup
          .string()
          .when("WoodCertified", {
            is: (WoodCertified: any) => WoodCertified === true,
            then: yup
              .string()
              .trim()
              .required(get("woodCertifiedSystem") + " : " + get("errorRequiredMessage"))
              .nullable(),
            otherwise: yup.string().nullable(),
          })
          .nullable(),
        WoodCertifiedSystemReference: yup
          .string()
          .when("WoodCertified", {
            is: (WoodCertified: any) => WoodCertified === true,
            then: yup
              .string()
              .trim()
              .required(get("woodCertifiedSystemReference") + " : " + get("errorRequiredMessage"))
              .nullable(),
            otherwise: yup.string().nullable(),
          })
          .nullable(),
      }),

      ProductRecycledMaterials: yup
        .array()
        .when("ProductRawMaterial.HasRecycledMaterial", {
          is: (HasRecycledMaterial: boolean) => HasRecycledMaterial === true,
          then: yup
            .array()
            .min(1, get("totalRecycleMaterialTableTitle") + " : " + get("errorMinimumOneMessage"))
            .required(get("totalRecycleMaterialTableTitle") + " : " + get("errorMinimumOneMessage"))
            .nullable(),
          otherwise: yup.array().nullable(),
        })
        .nullable(),

      //ConstructionPhase
      ProductConstruction: yup.object().shape({
        HasStorageRequirements: yup
          .number()
          .required(get("hasStorageRequirements") + " : " + get("errorRequiredMessage")),
        StorageRequirements: yup.string().when("HasStorageRequirements", {
          is: (HasStorageRequirements: number) => HasStorageRequirements === 1,
          then: yup
            .string()
            .trim()
            .required(get("storageRequirements") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string(),
        }),
        HasRequirementsOnSurroundingBuildingProducts: yup
          .number()
          .required(get("hasRequirementsOnSurroundingBuildingProducts") + " : " + get("errorRequiredMessage")),
        RequirementsOnSurroundingBuildingProducts: yup.string().when("HasRequirementsOnSurroundingBuildingProducts", {
          is: (HasRequirementsOnSurroundingBuildingProducts: number) =>
            HasRequirementsOnSurroundingBuildingProducts === 1,
          then: yup
            .string()
            .trim()
            .required(get("requirementsOnSurroundingBuildingProducts") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string(),
        }),
      }),

      //Distribuition
      ProductDistribution: yup.object().shape({
        ReusablePackaging: yup
          .string()
          .required(get("distributionReusablePackaging") + " : " + get("errorRequiredMessage")),
        ReclaimPackaging: yup
          .string()
          .required(get("distributionReclaimPackaging") + " : " + get("errorRequiredMessage")),
        HasPackagingSystem: yup
          .string()
          .required(get("distributionHasPackagingSystem") + " : " + get("errorRequiredMessage")),
        PackagingSystem: yup.string().when("HasPackagingSystem", {
          is: (HasPackagingSystem: string) => HasPackagingSystem === "1",
          then: yup
            .string()
            .trim()
            .required(get("distributionPackagingSystem") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string().nullable(),
        }),
        CanPackagingBeReused: yup
          .string()
          .required(get("distributionCanPackagingBeReused") + " : " + get("errorRequiredMessage")),
        CanPackagingBeMaterialRecycled: yup
          .string()
          .required(get("distributionCanPackagingBeMaterialRecycled") + " : " + get("errorRequiredMessage")),
        CanPackagingBeEnergyRecycled: yup
          .string()
          .required(get("distributionCanPackagingBeEnergyRecycled") + " : " + get("errorRequiredMessage")),
        ReturnSystem: yup.string().required(get("distributionReturnSystem") + " : " + get("errorRequiredMessage")),
      }),

      //Usage Phase
      ProductUsage: yup.object().shape(
        {
          EnergyHasLabel: yup
            .number()
            .required(get("usagePhaseEnergyLabelForProduct") + " : " + get("errorRequiredMessage")),

          EnergyLabelId: yup
            .string()
            .when("EnergyHasLabel", {
              is: (EnergyHasLabel: number) => EnergyHasLabel === 1,
              then: yup
                .string()
                .trim()
                .required(get("usagePhaseEnterLabelTitle") + " : " + get("errorRequiredMessage"))
                .nullable(),
              otherwise: yup.string().nullable(),
            })
            .nullable(),
          EnergyLabel2Id: yup
            .string()
            .when("EnergyHasLabel", {
              is: (EnergyHasLabel: number) => EnergyHasLabel === 1,
              then: yup
                .string()
                .trim()
                .required(get("usagePhaseEnterLabelTitleTwo") + " : " + get("errorRequiredMessage"))
                .nullable(),
              otherwise: yup.string().nullable(),
            })
            .nullable(),
          HasMaintenanceRequirements: yup
            .number()
            .required(get("usagePhaseOpertaionTitle") + " : " + get("errorRequiredMessage")),
          MaintenanceRequirements: yup.string().when("HasMaintenanceRequirements", {
            is: (HasMaintenanceRequirements: number) => HasMaintenanceRequirements === 1,
            then: yup
              .string()
              .trim()
              .required(get("usagePhaseOperationSpecify") + " : " + get("errorRequiredMessage")),
            otherwise: yup.string(),
          }),
          HasEnergyRequirements: yup
            .number()
            .required(get("usagePhaseEnergySupply") + " : " + get("errorRequiredMessage")),
          EnergyRequirements: yup.string().when("HasEnergyRequirements", {
            is: (HasEnergyRequirements: number) => HasEnergyRequirements === 1,
            then: yup
              .string()
              .trim()
              .required(get("usagePhaseOperationSpecify") + " : " + get("errorRequiredMessage")),
            otherwise: yup.string(),
          }),

          LongevityComment: yup.string().when(["LongevityMin", "LongevityMax"], {
            is: (LongevityMin: any, LongevityMax: any) =>
              (LongevityMin === null || !LongevityMin) && (LongevityMax === null || !LongevityMax),
            then: yup
              .string()
              .required(get("usagePhaseCommentError") + " : " + get("errorRequiredMessage"))
              .nullable(),
            otherwise: yup.string().nullable(),
          }),
        },
        [
          ["LongevityMin", "LongevityMax"],
          ["LongevityMin", "LongevityComment"],
          ["LongevityComment", "LongevityMax"],
        ],
      ),

      //Demolition
      ProductDisassembly: yup.object().shape({
        Safety: yup.number().required(get("demolitionSafety") + " : " + get("errorRequiredMessage")),
        SafetyComment: yup.string().when("Safety", {
          is: (Safety: number) => Safety === 1,
          then: yup
            .string()
            .trim()
            .required(get("demolitionSafetyComment") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string(),
        }),
      }),

      DocumentWasteCodeDeliveredItems: yup
        .array()
        .min(1, get("documentWasteCodeDelivereds") + " " + get("errorMinimumOneMessage"))
        .required(get("documentWasteCodeDelivereds") + " " + get("errorMinimumOneMessage"))
        .nullable(),
      //WasteManagment
      ProductWasteManagement: yup.object().shape({
        CoveredByRegulation2014_1075: yup
          .string()
          .required(get("coveredByRegulation") + " : " + get("errorRequiredMessage")),
        Reusable: yup.number().required(get("reusable") + " : " + get("errorRequiredMessage")),
        ReusableComment: yup.string().when("Reusable", {
          is: (Reusable: number) => Reusable === 1,
          then: yup
            .string()
            .trim()
            .required(get("ifYesSpecify") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string(),
        }),
        CanRecycle: yup.number().required(get("canRecycle") + " : " + get("errorRequiredMessage")),
        CanRecycleComment: yup.string().when("CanRecycle", {
          is: (CanRecycle: number) => CanRecycle === 1,
          then: yup
            .string()
            .trim()
            .required(get("ifYesSpecify") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string(),
        }),
        EnergyRecovery: yup.number().required(get("energyRecovery") + " : " + get("errorRequiredMessage")),
        EnergyRecoveryComment: yup.string().when("EnergyRecovery", {
          is: (EnergyRecovery: number) => EnergyRecovery === 1,
          then: yup
            .string()
            .trim()
            .required(get("ifYesSpecify") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string(),
        }),
        RestrictionsAndRecommendations: yup
          .number()
          .required(get("restrictionsAndRecommendations") + " : " + get("errorRequiredMessage")),
        RestrictionsAndRecommendationsComment: yup.string().when("RestrictionsAndRecommendations", {
          is: (RestrictionsAndRecommendations: number) => RestrictionsAndRecommendations === 1,
          then: yup
            .string()
            .trim()
            .required(get("ifYesSpecify") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string(),
        }),
        HazardousWasteDelivered: yup
          .string()
          .required(get("hazardousWasteDelivered") + " : " + get("errorRequiredMessage")),
        HazardousWasteMounted: yup
          .string()
          .required(get("hazardousWasteMounted") + " : " + get("errorRequiredMessage")),
      }),

      //Indoor Environment
      ProductIndoorEnvironment: yup.object().shape({
        HasCriticalMoistureCondition: yup
          .boolean()
          .required(get("indoorEnvironmentMoistureLevel") + " : " + get("errorRequiredMessage")),
        NoIndoorUsage: yup
          .boolean()
          .required(get("indoorEnvironmentNotIntended") + " : " + get("errorRequiredMessage")),
        NoEmissions: yup
          .boolean()
          .required(get("indoorEnvironmentNotProduceEmission") + " : " + get("errorRequiredMessage")),
        EmissionsNotMeasured: yup
          .boolean()
          .required(get("indoorEnvironmentNotEmissionMeasure") + " : " + get("errorRequiredMessage")),
        CriticalMoistureCondition: yup.string().when("HasCriticalMoistureCondition", {
          is: (HasCriticalMoistureCondition: boolean) => HasCriticalMoistureCondition === true,
          then: yup
            .string()
            .trim()
            .required(get("indoorEnvironmentEnterYes") + " : " + get("errorRequiredMessage")),
          otherwise: yup.string(),
        }),
      }),

      ProductEmissions: yup
        .array()
        .when(
          [
            "ProductIndoorEnvironment.NoIndoorUsage",
            "ProductIndoorEnvironment.NoEmissions",
            "ProductIndoorEnvironment.EmissionsNotMeasured",
          ],
          {
            is: (NoIndoorUsage: boolean, NoEmissions: boolean, EmissionsNotMeasured: boolean) =>
              NoIndoorUsage === false && NoEmissions === false && EmissionsNotMeasured === false,
            then: yup
              .array()
              .min(1, get("indoorEnvironmentEmissionerTableTitle") + " : " + get("errorMinimumOneMessage"))
              .required(get("indoorEnvironmentEmissionerTableTitle") + " : " + get("errorMinimumOneMessage"))
              .nullable(),
            otherwise: yup.array().nullable(),
          },
        ),
    })
    .required();

  const updateEbvdDocumentValidationSchema = yup
    .object({
      Product: yup.object().shape({
        Name: yup.string().required(get("articleName") + " : " + get("errorRequiredMessage")),
      }),
    })
    .required();

  const articleNoTableValidationSchema = yup
    .object({
      ProductSystemId: yup
        .string()
        .trim()
        .required(get("articleSelectIdSystemNumber") + " : " + get("errorRequiredMessage")),
      ProductId: yup
        .string()
        .trim()
        .required(get("articleIDTableTitle") + " : " + get("errorRequiredMessage")),
      VATNumber: yup.string().when("ProductSystemId", {
        is: (ProductSystemId: string) => ProductSystemId.includes("VAT"),
        then: yup
          .string()
          .required(get("articleVATNumberLabel") + " : " + get("errorRequiredMessage"))
          .nullable(),
        otherwise: yup.string().nullable(),
      }),
    })
    .required();

  const rawRecycledTableValidationSchema = yup
    .object({
      WeightOrWeightPercentage: yup
        .string()
        .matches(/^\d+$/, get("totalRecycleMaterialModalProportionWeight") + " : " + get("errorRequiredMessage"))
        .required(get("totalRecycleMaterialModalProportionWeight") + " : " + get("errorRequiredMessage"))
        .nullable(),
      ReusedMaterialPart: yup
        .string()
        .matches(/^\d+$/, get("totalRecycleMaterialModalRecycled") + " : " + get("errorRequiredMessage"))
        .required(get("totalRecycleMaterialModalRecycled") + " : " + get("errorRequiredMessage"))
        .nullable(),
      PreconsumerPart: yup
        .string()
        .matches(/^\d+$/, get("totalRecycleMaterialNotPassedConsumer") + " : " + get("errorRequiredMessage"))
        .required(get("totalRecycleMaterialNotPassedConsumer") + " : " + get("errorRequiredMessage"))
        .nullable(),
      PostconsumerPart: yup
        .string()
        .matches(/^\d+$/, get("totalRecycleMaterialPassedConsumer") + " : " + get("errorRequiredMessage"))
        .required(get("totalRecycleMaterialPassedConsumer") + " : " + get("errorRequiredMessage"))
        .nullable(),
      ExternalProductionWastePart: yup
        .string()
        .matches(/^\d+$/, get("totalRecycleMaterialModalSharePercentage") + " : " + get("errorRequiredMessage"))
        .required(get("totalRecycleMaterialModalSharePercentage") + " : " + get("errorRequiredMessage"))
        .nullable(),
      Material: yup
        .string()
        .required(get("totalRecycledMaterialModalMaterialTitle") + " : " + get("errorRequiredMessage")),
    })
    .required();

  const attachmentValidationSchema = yup
    .object({
      Attachment: yup.string().required(get("attachmentBlockTitle") + " : " + get("errorRequiredMessage")),
    })
    .required();

  const referenceValidationSchema = yup
    .object({
      Reference: yup.string().required(get("documentReference") + " : " + get("errorRequiredMessage")),
    })
    .required();

  const documentProductGroupValidation = yup
    .object({
      ProductGroupSystemId: yup
        .string()
        .trim()
        .required(get("articleSelectIdSystemNumber") + " : " + get("errorRequiredMessage")),
      ProductGroupCode: yup
        .string()
        .trim()
        .required(get("articleProductGroupID") + " : " + get("errorRequiredMessage")),
    })
    .required();

  const documentWasteCodeDeliveredItemsValidationSchema = yup
    .object({
      WasteCodeDeliveredId: yup
        .number()
        .required(get("wasteManagementDeliveredTableWastecode") + ":" + get("errorRequiredMessage"))
        .moreThan(0, get("wasteManagementDeliveredTableWastecode") + ":" + get("errorRequiredMessage")),
    })
    .required();

  const documentWasteCodeMountedItemsValidationSchema = yup
    .object({
      WasteCodeMountedId: yup
        .number()
        .required(get("wasteManagementMountedTableWastecode") + ":" + get("errorRequiredMessage"))
        .moreThan(0, get("wasteManagementMountedTableWastecode") + ":" + get("errorRequiredMessage")),
    })
    .required();

  const rawMaterialArticleValidationSchema = yup
    .object({
      PrimaryProductionCountryId: yup
        .number()
        .required(get("rawMaterialModalCountryRawMaterialTitle") + " : " + get("errorRequiredMessage"))
        .moreThan(0, get("rawMaterialModalCountryRawMaterialTitle") + " : " + get("errorRequiredMessage")),
      PrimaryProductionCity: yup
        .string()
        .required(get("rawMaterialModalCityRawMaterialTitle") + " : " + get("errorRequiredMessage")),
    })
    .required();

  const emissionTableValidationSchema = yup
    .object({
      EmissionType: yup
        .string()
        .trim()
        .required(get("indoorModalEmissionTitle") + " : " + get("errorRequiredMessage")),
      Method1: yup
        .string()
        .trim()
        .required(get("indoorModalMeasuringMethodStandardTitle1") + " : " + get("errorRequiredMessage")),
      Result1ComparisonOperatorId: yup.number().when("Method1", {
        is: (Method1: string) => Method1 !== "",
        then: yup
          .number()
          .required(get("indoorModalResult1") + " : " + get("errorRequiredMessage"))
          .nullable(),
        otherwise: yup.number().nullable(),
      }),
      Result1: yup.string().when("Method1", {
        is: (Method1: string) => Method1 !== "",
        then: yup.string().required(get("errorRequiredMessage")).nullable(),
        otherwise: yup.string().nullable(),
      }),
      Unit1: yup.string().when("Method1", {
        is: (Method1: string) => Method1 !== "",
        then: yup
          .string()
          .trim()
          .required(get("indoorModalUnit1") + " : " + get("errorRequiredMessage")),
        otherwise: yup.string(),
      }),
      MeasurementTimeInterval1: yup.string().when("Method1", {
        is: (Method1: string) => Method1 !== "",
        then: yup
          .string()
          .required(get("indoorTimeIntervalMeasurement1") + " : " + get("errorRequiredMessage"))
          .nullable(),
        otherwise: yup.string().nullable(),
      }),
      MeasurementTimeInterval1Unit: yup.string().when("Method1", {
        is: (Method1: string) => Method1 !== "",
        then: yup.string().trim().required(get("errorRequiredMessage")),
        otherwise: yup.string(),
      }),

      Unit2: yup.string().when("Method2", {
        is: (Method2: string) => Method2 !== "",
        then: yup
          .string()
          .trim()
          .required(get("indoorModalUnit2") + " : " + get("errorRequiredMessage")),
        otherwise: yup.string(),
      }),
      Result2: yup.string().when("Method2", {
        is: (Method2: string) => Method2 !== "",
        then: yup.string().required(get("errorRequiredMessage")).nullable(),
        otherwise: yup.string().nullable(),
      }),
      Result2ComparisonOperatorId: yup.number().when("Method2", {
        is: (Method2: string) => Method2 !== "",
        then: yup
          .number()
          .required(get("indoorModalResult2") + " : " + get("errorRequiredMessage"))
          .nullable(),
        otherwise: yup.number().nullable(),
      }),
      MeasurementTimeInterval2: yup.string().when("Method2", {
        is: (Method2: string) => Method2 !== "",
        then: yup
          .string()
          .required(get("indoorTimeIntervalMeasurement2") + " : " + get("errorRequiredMessage"))
          .nullable(),
        otherwise: yup.string().nullable(),
      }),
      MeasurementTimeInterval2Unit: yup.string().when("Method2", {
        is: (Method2: string) => Method2 !== "",
        then: yup.string().trim().required(get("errorRequiredMessage")),
        otherwise: yup.string().nullable(),
      }),
    })
    .required();

  const existingArticleSubComponentValidationSchema = yup
    .object()
    .shape({
      ExistingArticleCheckboxItem: yup
        .array()
        .min(1, get("existingArticleComponentItem") + " : " + get("errorMinimumOneMessage"))
        .required(get("existingArticleComponentItem") + " : " + get("errorMinimumOneMessage"))
        .nullable(),
    })
    .required();

  const articleSubComponentValidationSchema = yup
    .object()
    .shape(
      {
        Component: yup.string().required(get("errorRequiredMessage")),
        ProductPhaseId: yup.number().typeError(get("errorRequiredMessage")).nullable(),
        WeightComparisonOperatorId: yup.string().required(get("errorRequiredMessage")).nullable(),
        MinWeightPercentage: yup.string().when("WeightComparisonOperatorId", {
          is: (WeightComparisonOperatorId: string) =>
            WeightComparisonOperatorId !== "2" && WeightComparisonOperatorId !== "3",
          then: yup.string().required(get("errorRequiredMessage")).nullable(),
          otherwise: yup.string().nullable(),
        }),
        MaxWeightPercentage: yup.string().when("WeightComparisonOperatorId", {
          is: (WeightComparisonOperatorId: string) =>
            WeightComparisonOperatorId !== "1" &&
            WeightComparisonOperatorId !== "4" &&
            WeightComparisonOperatorId !== "7",
          then: yup.string().required(get("errorRequiredMessage")).nullable(),
          otherwise: yup.string().nullable(),
        }),
        EGNumber: yup.string().when(["Substance", "CASNumber", "AlternativeCode"], {
          is: (Substance: string, CASNumber: string, AlternativeCode: string) =>
            Substance !== "" && CASNumber === "" && AlternativeCode === "",
          then: yup.string().required(get("errorRequiredMessage")).nullable(),
          otherwise: yup.string().nullable(),
        }),
        CASNumber: yup.string().when(["Substance", "EGNumber", "AlternativeCode"], {
          is: (Substance: string, EGNumber: string, AlternativeCode: string) =>
            Substance !== "" && EGNumber === "" && AlternativeCode === "",
          then: yup.string().required(get("errorRequiredMessage")).nullable(),
          otherwise: yup.string().nullable(),
        }),
        AlternativeCode: yup.string().when(["Substance", "EGNumber", "CASNumber"], {
          is: (Substance: string, EGNumber: string, CASNumber: string) =>
            Substance !== "" && EGNumber === "" && CASNumber === "",
          then: yup.string().required(get("errorRequiredMessage")).nullable(),
          otherwise: yup.string().nullable(),
        }),
        IdentityNumberComment: yup.string().when(["Substance", "EGNumber", "CASNumber", "AlternativeCode"], {
          is: (Substance: string, EGNumber: string, CASNumber: string, AlternativeCode: any) =>
            Substance !== "" && EGNumber === "" && CASNumber === "" && AlternativeCode !== "",
          then: yup.string().required(get("errorRequiredMessage")).nullable(),
          otherwise: yup.string().nullable(),
        }),
      },
      [
        ["WeightComparisonOperatorId", "MinWeightPercentage"],
        ["WeightComparisonOperatorId", "MaxWeightPercentage"],
        ["CASNumber", "AlternativeCode"],
        ["EGNumber", "AlternativeCode"],
        ["IdentityNumberComment", "AlternativeCode"],
        ["EGNumber", "CASNumber"],
      ],
    )
    .required();

  const createDocumentValidationSchema = yup
    .object({
      ProductName: yup.string().required(get("documentProductNameErrorMessage")),
      UserGroupId: yup.number().required(get("documentOrganisationErrorMessage")),
      DocumentSystemId: yup.string().required(get("documentSystemIDErrorMessage")),
      DocumentSystemCode: yup.string().required(get("documentSystemCodeErrorMessage")),
    })
    .required();

  const importDocumentValidationSchema = yup
    .object({
      UserGroupId: yup.number().required(get("documentOrganisationErrorMessage")),
      FileBuffer: yup.string().required(get("importFileBufferErrorMessage")),
    })
    .required();

  const createUserValidationSchema = yup
    .object({
      Name: yup.string().required(get("errorRequiredMessage")),
      Email: yup.string().matches(/\S+@\S+\.\S+/, get("errorRequiredMessage")),
    })
    .required();
  const createAccountValidationSchema = yup
    .object({
      Name: yup.string().required(get("errorRequiredMessage")),
      OrgNumber: yup.string().required(get("errorRequiredMessage")),
      VatNumber: yup.string().required(get("errorRequiredMessage")),
      InvoiceCountryId: yup.number().required(get("errorRequiredMessage")),
      InvoiceAddress: yup.string().required(get("errorRequiredMessage")),
      InvoiceAreaCode: yup.string().required(get("errorRequiredMessage")),
      InvoiceCity: yup.string().required(get("errorRequiredMessage")),
      InvoiceReference: yup.string().required(get("errorRequiredMessage")),
      InvoiceEmail: yup
        .string()
        .matches(/\S+@\S+\.\S+/, get("errorRequiredMessage"))
        .required(get("errorRequiredMessage")),
      CountryId: yup.number().required(get("errorRequiredMessage")),
      Address: yup.string().required(get("errorRequiredMessage")),
      AreaCode: yup.string().required(get("errorRequiredMessage")),
      City: yup.string().required(get("errorRequiredMessage")),
      Reference: yup.string().required(get("errorRequiredMessage")),
      Email: yup
        .string()
        .matches(/\S+@\S+\.\S+/, get("errorRequiredMessage"))
        .required(get("errorRequiredMessage")),
      Phone: yup.string().required(get("errorRequiredMessage")),
      TurnOver: yup.string().required(get("errorRequiredMessage")),
    })
    .required();
  const organizationInformationValidationSchema = yup
    .object({
      Name: yup.string().required(get("errorRequiredMessage")),
    })
    .required();
  const userRegistrationValidationSchema = yup
    .object({
      Name: yup.string().required(get("errorRequiredMessage")),
    })
    .required();
  return {
    ebvdDocumentValidationSchema,
    updateEbvdDocumentValidationSchema,
    articleNoTableValidationSchema,
    rawRecycledTableValidationSchema,
    attachmentValidationSchema,
    referenceValidationSchema,
    documentProductGroupValidation,
    rawMaterialArticleValidationSchema,
    emissionTableValidationSchema,
    documentWasteCodeDeliveredItemsValidationSchema,
    documentWasteCodeMountedItemsValidationSchema,
    articleSubComponentValidationSchema,
    existingArticleSubComponentValidationSchema,
    createDocumentValidationSchema,
    importDocumentValidationSchema,
    createUserValidationSchema,
    organizationInformationValidationSchema,
    userRegistrationValidationSchema,
    createAccountValidationSchema,
  };
};

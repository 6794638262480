import { useState, useEffect } from "react";
import { Box, Grid, Pagination, TableRow, TextField, Typography } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useDebounce } from "use-debounce";
import { useRecoilState } from "recoil";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useTranslation } from "../../../helpers/useTranslation";
import { InternalSearchPanel } from "./InternalSearchPanel";
import { InternalSearchToolBar } from "./InternalSearchToolBar";
import InternalSearchItemLists from "./InternalSearchItemLists";
import { useGetDocumentsInternalSearch } from "../../../services/documentService";
import { internalSearchFilterAtom } from "../../../recoil/atom";
import InternalSearchSubFilters from "./InternalSearchSubFilters";
import { InternalSearchSubToolBar } from "./InternalSearchSubToolBar";
import { CustomChips } from "../../../components/CustomChips/CustomChips";
import { Loader } from "../../../components/Loader/Loader";
import { useLocation } from "react-router";
import { useGetUser } from "../../../services/userService";

type Props = {
  handleCreateEBVDForm: () => void;
};

export const InternalSearchPage = ({ handleCreateEBVDForm }: Props) => {
  const [documentIds, setDocumentIds] = useState<number[]>([]);
  const [filter, setFilter] = useRecoilState(internalSearchFilterAtom);
  const query = new URLSearchParams(useLocation().search);
  const querySearch = query.get("search");
  const { data: user } = useGetUser();

  useEffect(() => {
    if (user !== undefined) {
      const userGroupIdsIds: number[] = user.UserGroups.map((u) => u.UserGroupId);
      setFilter((oldFilter) => ({
        ...oldFilter,
        CompanyIds: userGroupIdsIds,
        RowStartIndex: 0,
      }));
    }
  }, [setFilter, user]);

  useEffect(() => {
    if (querySearch) {
      setFilter((oldFilter) => ({
        ...oldFilter,
        TextFilter: querySearch,
        RowStartIndex: 0,
      }));
    }
  }, [querySearch, setFilter]);

  useEffect(() => {
    if (querySearch) {
      setFilter((oldFilter) => ({
        ...oldFilter,
        GTINNumbers: [querySearch],
        RowStartIndex: 0,
      }));
    }
  }, [querySearch, setFilter]);

  const [shouldDebounce, setShouldDebounce] = useState<boolean>(true);
  const [debouncedFilterValues] = useDebounce(filter, 750);
  const { data, isLoading, isStale } = useGetDocumentsInternalSearch(shouldDebounce ? debouncedFilterValues : filter);
  const [page, setPage] = useState(1);
  const { get } = useTranslation();

  useEffect(() => {
    setShouldDebounce(true);
  }, [data]);

  useEffect(() => {
    if (filter.RowStartIndex === 0) {
      setPage(1);
    }
  }, [filter.RowStartIndex]);
  return (
    <>
      <Box sx={{ flexGrow: 1, display: "flex", marginTop: 5 }}>
        <Grid
          sx={{
            flexBasis: "30%",
            pl: 2,
            ".MuiTabs-indicator": {
              left: 0,
              top: "10px",
              width: "5px",
              marginTop: "9px",
              maxHeight: "30px",
              borderRadius: "2.5px",
              paddingLeft: "0px!important",
            },
          }}
        >
          <TextField
            sx={{ mt: "0px", ml: 4, paddingRight: "25px", width: "90%" }}
            label={get("tempSearchLabel")}
            autoComplete="off"
            value={filter.TextFilter}
            InputLabelProps={{ shrink: true }}
            onChange={(event) =>
              setFilter({
                ...filter,
                TextFilter: event.target.value,
                RowStartIndex: 0,
              })
            }
          ></TextField>
          <Box sx={{ flexGrow: 1, display: "flex", marginTop: 5 }}>
            <TextField
              sx={{ mt: "0px", ml: 4, paddingRight: "25px", width: "90%" }}
              label="GTIN"
              autoComplete="off"
              value={filter.GTINNumbers}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                const value = event.target.value.trim();
                setFilter((oldFilter) => ({
                  ...oldFilter,
                  GTINNumbers: value ? [value] : [],
                  RowStartIndex: 0,
                }));
              }}
            />
          </Box>
          <InternalSearchSubFilters setFilter={setFilter} filter={filter} />
        </Grid>

        <InternalSearchPanel>
          <Grid container>
            <Grid item md={8} sx={{ paddingLeft: "0px" }}>
              <CustomChips setFilter={setFilter} filter={filter} />
            </Grid>
            <AuthenticatedTemplate>
              {user && user?.UserGroups.filter((x) => x.CanCreateNew).length > 0 && (
                <Grid item md={4}>
                  <InternalSearchToolBar handleCreateEBVDForm={handleCreateEBVDForm} />
                </Grid>
              )}
            </AuthenticatedTemplate>
          </Grid>

          <InternalSearchSubToolBar
            setFilter={setFilter}
            filter={filter}
            documentList={data?.Items || []}
            documentIds={documentIds}
            setDocumentIds={setDocumentIds}
          />

          {isLoading && isStale ? (
            <Loader />
          ) : (
            <>
              {data?.Items.length ? (
                <>
                  <TableContainer sx={{ width: "100%" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableBody>
                        {data?.Items.map((item, idx) => (
                          <TableRow key={idx}>
                            <InternalSearchItemLists
                              item={item}
                              key={idx}
                              documentIndex={idx}
                              setDocumentIds={setDocumentIds}
                              documentIds={documentIds}
                            />
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <Typography variant="h6">{get("noResultTextSearchPage")}</Typography>
                </Box>
              )}
              <Box sx={{ display: isLoading && isStale ? "hidden" : "flex", justifyContent: "center", mt: 3 }}>
                <Pagination
                  count={Math.ceil((data?.TotalItemCount || filter.RowCount) / filter.RowCount)}
                  color="primary"
                  shape="rounded"
                  page={page}
                  onChange={(_e, currentPage) => {
                    setPage(currentPage);
                    setShouldDebounce(false);
                    setFilter({ ...filter, RowStartIndex: (currentPage - 1) * filter.RowCount });
                    window.scrollTo({
                      top: 0,
                      behavior: "auto",
                    });
                  }}
                />
              </Box>
            </>
          )}
        </InternalSearchPanel>
      </Box>
    </>
  );
};

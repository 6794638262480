import { TranslatedKey } from "./translatedKey";

export const sv: Record<TranslatedKey, string> = {
  aboutSectionContent1:
    "Webbapplikationen ersätter den tidigare applikationen som laddades ner till din dator. Det innebär att du som användare nu har åtkomst till eBVD-systemet och dina byggvarudeklarationer direkt i webbläsaren.",
  aboutSectionContent2:
    "I det övre högra hörnet kan du skapa alternativt logga in på ditt eBVD-konto. Du har även möjlighet att söka bland publicerade eBVDer och direkt ladda ner dessa som pdf-filer till din dator. För att använda sökfilter vid din sökning får du klicka på knappen ”Sök eBVD” ovan. Du behöver inte logga in i systemet för att kunna söka bland publicerade eBVDer. Information om driftstörningar eller systemuppdateringar kommer att publiceras under avsnittet ”Systeminformation” på startsidan. Manual och användarinstruktion för eBVD-systemet hittar du nedan. Har du ytterligare frågor om hur eBVD fungerar, vänligen kontakta supporten på ",
  aboutSectionContent3:
    "Vi värnar om din integritet och strävar efter att erbjuda digitala tjänster som inte äventyrar våra besökares, kunders och användares integritet. Genom denna Integritetspolicy från eBVD förklarar vi hur vårt proaktiva integritetskyddsarbete har integrerats i de onlinetjänster som erbjuds genom eBVD-systemet. Denna integritetspolicy avser att informera om behandlingen av personuppgifter som sker vid användning av eBVD applikationen och databasen (eBVD-systemet), som tillhandahållas av eBVD i Norden AB (eBVD). Integritetspolicyn kan du hitta här [infoga länk till hjälpdokument-sidan].",
  aboutSectionTitle: "Om eBVD webb",
  aboutSectionTitle2: "Integritet",
  actionLogin: "Logga in",
  actionLogout: "Logga ut",
  addAttachment: "Lägg till Bilaga",
  addReference: "Lägg till hänvisning",
  addUser: "Lägg till användare till din organisationsprofil",
  articleAddItemNumber: "Lägg till artikelnummer",
  articleAddProductGroup: "Lägg till Produktgrupp",
  articleAddSubComponentTitle: "Lägg till vara och eller delkomponent",
  articleAddSubComponentError: "Information saknas i obligatoriskt fält",
  articleAttachmentTitle: "Bilagor",
  articleConceptTitle: "Artikel-nr/ID-begrepp",
  articleDeclarationNumber: "Prestandadeklarationsnummer",
  articleDescription: "Varubeskrivning",
  articleEditSubComponentTitle: "Redigera vara och/eller delkomponent",
  articleGTINTitle: "Varför anges inget GTIN?",
  articleIDTableTitle: "Artikelid",
  articleIdentitiesTableTitle: "Artikelidentiteter",
  articleName: "Varunamn",
  articleOtherInformation: "Övriga upplysningar",
  articleProductGroup: "Varugrupp/Varugruppsindelning",
  articleProductGroupID: "Varugruppsid",
  articleProductGroupSystem: "Varugruppssystem",
  articleProductRegulation: "Finns en prestandadeklaration, enligt byggproduktförordningen, framtagen för varan?",
  articleReferenceTitle: "Hänvisningar",
  articleSelectIdSystemNumber: "Välj det id-system som ska användas ",
  articleSelectIdSystemNumberLabel: "varunamn(separera varje artikel med ett komma(,) eller semikolon(;))",
  articleSubComponentEditHphraseTableTitle: "Redigera H-fraser",
  articleSubComponentExistingArticleTableTitle: "Lägga till befintliga",
  articleSubComponentMaterialTableTitle: "Lägg till material",
  articleSubComponentTableAddTitle: "Lägg till",
  articleSubComponentTableDeleteTitle: "Ta bort",
  articleSubComponentTableEditTitle: "Ändra",
  articleSubComponentTableHide: "Dölj",
  articleSubComponentTableShow: "Visa",
  articleSubComponentTableSubstanceTitle: "Lägg till ämne",
  articleSubComponentTableTitle:
    "Ange vilka komponenter, material och ämnen som varan/den kemiska produkten består av. Ange alltid sammansättningen av varan/kemiska produkten vid både leverans och efter inbyggnad. Skiljer sig sammansättningen från leverans till inbyggnad ska innehållet anges som separata poster för respektive fas, i annat fall kan fasen Leverans/inbyggd användas.",
  articleVATNumber: "Internt varunamn(separera varje artikel med ett komma(,) eller semikolon(;))",
  articleVATNumberLabel: "VAT-varunamn",
  attachmentBlockTitle: "Bilaga",
  back: "Tillbaka",
  bulkManagementDeleteTitle: "Ta bort",
  bulkManagementExportPdfTitle: "Exportera PDF",
  bulkManagementExportXMLTitle: "Exportera XML",
  bulkManagementExportExcelTitle: "Exportera Excel",
  bulkManagementHistoryTitle: "Historik publicering och avpublicering",
  bulkManagementPublishTitle: "Publicera dokument",
  bulkManagementUnpublishTitle: "Avpublicera dokument",
  bulkManagementTitle: "Bulkhantera",
  buttonVerify: "Kontrollera",
  canRecycle: "Är materialåtervinning möjlig för hela eller delar av varan när den byts ut/kasseras?",
  cancelButtonText: "Avbryt",
  cantPublishDocuments:
    "Det går inte att publicera detta/dessa dokument. Antingen saknas behörighet för detta eller så är det/de redan publicerade",
  casCheckSumErrorMessage: "Kontrollsiffran stämmer inte för CAS-numret",
  checmicalComponentAlternateCodeTableTitle: "Alternativ beteckning",
  checmicalComponentECTableTitle: "EC",
  checmicalComponentHphraseTableTitle: "H-fraser",
  chemicalComponentCASTableTitle: "CAS",
  chemicalComponentConcentrationTableTitle: "Koncentrationsintervall",
  chemicalComponentPhaseTableTitle: "Fas",
  chemicalComponentTitle: "Namn",
  chamicalComponentComponentMaterialSubstanceTitle: "Komponent/Material/Ämne",
  chemicalComponentPhasingTableTitle: "Utfasningsämne",
  chemicalComponentRouteofExposureTableTitle: "Exponeringsvägar/organ",
  chemicalComponentSubstanceTableTitle: "Ämne",
  chemicalComponentTableComponentTitle: "Komponent",
  chemicalComponentTableMaterialTitle: "Material",
  chemicalContentArticle: "Vara och eller delkomponenter",
  chemicalContentArticleSubComponentTableTitle: "Vara och eller delkomponenter",
  chemicalContentCandidateListEdition: "Ange vilken utgåva av kandidatförteckningen som har använts (År, månad, dag)",
  chemicalContentCandidateListTableTitle: "Kandidatlistan",
  chemicalContentCommentTableTitle: "Kommentar",
  chemicalContentDeclaredWeightComment: "Om 100% materialinnehåll ej är deklarerat, ange orsak",
  chemicalContentDeclaredWeightPercentage: "Ange hur stor del av materialinnehållet som är deklarerat [vikt-%]",
  chemicalContentDescription1:
    "Kemisk produkt är ett kemiskt ämne eller en beredning av kemiska ämnen som inte är en vara (definition enligt 14 kapitlet, 2 §.miljöbalken (1998:808)).",
  chemicalContentDescription2:
    "En vara är ett föremål som under produktionen får en särskild form, yta eller design, vilken i större utsträckning än dess kemiska sammansättning bestämmer dess funktion (definition enligt Reach, kapitel 2, artikel 33).",
  chemicalContentDescription3:
    "Kemiska produkter ska innehållsdeklareras enligt samma princip som i säkerhetsdatablad, se REACH bilaga II, 3.2.1-3.2.4. Utöver detta ska samtliga ämnen som ingår med ≥2% deklareras (gäller även oklassade ämnen).",
  chemicalContentDescription4:
    "Ange kemiskt innehåll. För sammansatta varor ska kemiskt innehåll anges på komponentnivå enligt principen 'en gång vara alltid vara' från kemikalielagstifningen REACH art 33.",
  chemicalContentDescription5:
    "För varor gäller samma redovisningskarv som för blandningar som inte klassificeras som farliga,med undantag av de fall då ämnets förekomst i en halt somär lägre än redovisningskravets hade gjort att en blandning hade blivit klassificerad som farlig.I dessa fall är det den halten som hade gjort att en blandning blivit klassificerad som farlig som utgör haltgränsen.",
  chemicalContentDescription6:
    "Utöver detta ska samtliga ämnen som ingår med >= 2% deklareras(gäller även oklassade ämnen)",
  chemicalContentHasProductClassification: "Är den kemiska produkten som helhet klassificerad?",
  chemicalContentHasSafetyDatasheet: "Finns säkerhetsdatablad?",
  chemicalContentInformation: "Övriga upplysningar",
  chemicalContentProductClassification:
    "Om Ja, ange produktens klassificering enligt förordning (EG) nr 1272/2008) med Farokategori (förkortad), H-fras",
  chemicalContentProductContentTypeId: "Gäller deklarationen en vara eller kemisk produkt? ",
  chemicalContentProductNanoMaterial:
    "Innehåller den kemiska produkten nanomaterial som är medvetet tillsatt för att uppnå en viss funkcion, ange dessa nedan",
  chemicalContentProductNanoMaterialIsRegistered:
    "Har förekomsten av nanomaterial som medvetet tillsatts i anmälningspliktiga kemiska produkter redovisats till produktregistret?",
  chemicalContentProductVolatileOrganicPart:
    "Ange andelen organiska ämnen [g/liter], gäller endast tätningsmedel, färg, lack, lim, och lösningsmedel.",
  chemicalContentRoHS: "Varan omfattas av RoHS-direktivet",
  chemicalContentWeight: "Ange varans vikt:",
  chemicalExistingArticle: "Artikel",
  chemicalExistingButtonName: "Lägg till komponent",
  chemicalExistingDialogTitle: "Lägg till existerande komponent",
  chemicalExistingPhase: "Fas",
  chemicalExistingSelected: "Vald",
  chemicalExistingTradeName: "Varunamn",
  clearFilterText: "Rensa Filter",
  textSearch: "Sök",
  close: "Stäng",
  companyDigitalTransferTitle: "Digital överföring av eBVD via web-API",
  companyDigitalTransferText:
    "Digital överföring mellan eBVD-systemet och andra databaser via API gör det effektivt att både tillhandahålla och använda miljöinformation för byggmaterial. Standardinställningen i eBVD-systemet är att digital överföring av ej konfidentiell information i publicerade eBVDer godkänns till uppkopplade databaser. Ni har förstås fortsatt kontroll över er produktinformation och kan självklart styra huruvida eBVD i Norden AB får dela era publicerade eBVDer, samt dess innehåll, med de uppkopplade organisationerna eller ej. Företagets administratör kan hantera samt avaktivera den digitala överföringen under fliken ”Digital överföring”.",
  constructionPhaseComment: "Övriga upplysningar",
  contactSectionAddress1: "Box 5501, 114 85 STOCKHOLM",
  contactSectionAddress2: "Besöksadress: Storgatan 19",
  contactSectionAddressTitle: "Byggmaterialindustrierna",
  contactSectionAddressIvl: "Valhallavägen 81, 114 28 Stockholm",
  contactSectionContactIvl: "Kontakt: eBVD@ivl.se",
  contactSectionInfoIvl1: "eBVD i Norden AB är ett dotterbolag till ",
  contactSectionInfoIvlLink: "IVL Svenska Miljöinstitutet",
  contactSectionInfoIvl2: "och Byggmaterialindustrierna.",
  contactSectionAddressTitleIvl: "IVL Svenska Miljöinstitutet",
  contactSectionLink1: "Användarinstruktioner",
  contactSectionLink2: "Support",
  contactSectionLink3: "Vanliga frågor",
  containsWood: "Träråvara ingår?",
  copyDocumentButton: "Kopiera eBVD",
  copyDocumentInformation: "Kopiera följande information:",
  coveredByRegulation:
    "Omfattas den levererade varan av förordningen (2014:1075) om producentansvar för elektriska och elektroniska produkter när den blir avfall?",
  createEbvdButton: "Skapa eBVD",
  d6866: "Ingående biobaserad råvara är testat enligt ASTM testmethod D6866",
  deleteUserTitle: "Ta bort användare",
  deleteUserMessage: "Är du säker på att du vill ta bort användaren ",
  deleteDocumentTitle: "Radera eBVD : ",
  deleteDocumentMessage: "Är du säker?",
  deleteProductContentComponentMessage: "Är du säker?",
  deleteProductContentComponentTitle: "Radera vara eller delkomponent: ",
  deliveredArticleSession: "Levererad vara",
  demolitionCanBeSeparated: "Går varan att separera i rena materialslag för materialåtervinning? ",
  demolitionComment: "Övriga upplysningar",
  demolitionPrepared: "Är varan förberedd för demontering (isärtagning)?",
  demolitionPreparedComment: "Om ja på någon av frågorna ovan, specificera/ange vilka materialslag det gäller:",
  demolitionSafety:
    "Kräver varan särskilda åtgärder för skydd av människors hälsa och/eller miljö vid rivning, demontering eller för att separera material?",
  demolitionSafetyComment: "Om ja, specificera",
  digitalTransferInformationAccess: "Tillgång till information",
  digitalTransferName: "Namn",
  digitalTransferSeleted: "Vald",
  distributionCanPackagingBeEnergyRecycled: "Kan emballage/förpackning energiåtervinnas?",
  distributionCanPackagingBeMaterialRecycled: "Kan emballage/förpackning materialåtervinnas?",
  distributionCanPackagingBeReused: "Kan emballage/förpackning återanvändas?",
  distributionComment: "Övriga upplysningar",
  distributionHasPackagingSystem:
    "Är leverantören ansluten till ett system för producentansvar för förpackningar? (gäller den aktuella varan)",
  distributionPackagingSystem: "Om ja, vilken förpackning och vilket system",
  distributionReclaimPackaging: "Återtar leverantören emballage för varan?",
  distributionReturnSystem: "Tillämpar leverantören Retursystem Byggpall?",
  distributionReusablePackaging: "Tillämpar leverantören system med flergångsemballage för varan?",
  distributionSection: "Distribution av färdig vara",
  documentCancel: "Ångra",
  documentCommentPublish: "eBVD:n är publicerad och kan därför inte ändras",
  documentCommentSaved: "Dokumentet är sparat men inte publicerat",
  documentCreateNeweBVD: "Skapa ny eBVD",
  documentCreated: "Skapad",
  documentLastsaved: "Senast sparad",
  documentLatestPublished: "Senast publicerade versionen",
  documentLatestVersion: "Senaste versionen",
  documentModalDocumentIDSystem: "Ange dokumentidsystem",
  documentModalOrganisation: "Organisation",
  documentModalProductName: "Varunamn",
  documentModalTemplate: "Mall",
  documentModalTitle: "Skapa ny eBVD",
  documentModalValidFrom: "Giltig från",
  documentOrganisationErrorMessage: "Organisation krävs",
  documentProductNameErrorMessage: "Varunamn krävs",
  documentPublished: "Publicerad",
  documentAboutToPublish: "Du är nu på väg att publicera ",
  documentConfirmPublishing: "Bekräfta Publicering",
  documentPublishNoMoreChanges:
    "Observera att inga fler ändringar kommer att kunna göras i detta dokument efter att det har publicerats. För att göra ändringar behöver en ny version av dokumentet skapas.",
  documentsPublishNoMoreChanges:
    "Observera att inga fler ändringar kommer att kunna göras i dessa dokument efter att de har publicerats. För att göra ändringar behöver en ny version av dokumenten skapas.",
  documentPublishAgreement1:
    "Härmed intygas att innehållsdeklarationen är redovisad i enlighet med dokumentationskraven.",
  documentPublishAgreement2:
    "Härmed intygas att innehåll av kandidatämnen, ämnen i tillståndsförteckningen (REACH bilaga XIV) samt ämnen som är begränsade enligt REACH bilaga XVII och som ingår i halter om 0,1% eller mer är komplett redovisat per ",
  documentReference: "Hänvisning",
  documents: "dokument",
  documentStatus: "Status",
  documentStatusChanged: "Status ändrad",
  documentStatusFilterChip: "Dokument Status: ",
  documentStatusPublish: "Publicerad",
  documentStatusSaved: "Skapad",
  documentSystemCodeErrorMessage: "Ange documentsystemcode krävs",
  documentSystemIDErrorMessage: "Ange documentidsystem krävs",
  documentValidFrom: "Giltig från",
  documentValidTo: "Giltig till",
  documentVersion: "Version",
  documentVersionLowercase: "version ",
  documentWasteCodeDelivereds: "Ange avfallskod för den levererade varan när den blir avfall:",
  documentWasteCodeMounteds: "Ange avfallskod för den inbyggda varan",
  eBVDDocumentSaveMessage: "eBVD sparad.",
  ecCheckSumErrorMessage: "Kontrollsiffran stämmer inte för EG-numret",
  editAttachment: "Redigera Bilaga",
  editReference: "Redigera hänvisning",
  energyRecovery: "Är energiåtervinning möjlig för hela eller delar av varan när den byts ut/kasseras?",
  environmentalImpactEN15804: "Finns en miljövarudeklaration framtagen enligt EN15804 eller ISO 14025 för varan?",
  environmentalImpactEPD: "Registreringsnummer / ID-nummer för EPD:",
  environmentalImpactPCR: "Om ja, vilka produktspecifika regler har använts, s.k. PCR (Product Category Rules):",
  environmentalLifecyclePerspective:
    "Om miljövarudeklaration eller livscykelanalys saknas, beskriv hur miljöpåverkan av varan beaktas ur ett livscykelperspektiv:",
  errorMessage: "Någonting gick fel, om problemet kvarstår kontakta en administratör.",
  errorMessageTitle: "Felmeddelande",
  errorMessageDescription:
    "Det saknas information i obligatoriska fält. Lägg till saknad information för att kunna publicera ditt dokument.",
  errorRequiredMessage: "Obligatoriskt",
  errorTryAgainButton: "Försök igen",
  existingArticleComponentItem: "Existerande komponent",
  exportError: "Error vid Export: ",
  fetchingData: "Hämtar data.. Var god vänta",
  fieldAddress: "Adress",
  fieldAlternativeCode: "Alternativ beteckning",
  fieldAreaCode: "Postnummer",
  fieldAuthorisationList: "Ämne på tillståndsförteckningen",
  fieldCASNum: "CAS Nr",
  fieldCadmium: "Kadmium (Cd) eller kadmiumföreningar",
  fieldCandidateList: "Ämne på kandidatlistan",
  fieldCity: "City",
  fieldComment: "Kommentar",
  fieldCompanyAccounting: "Om ja,ange redovisningsprinciper/standard",
  fieldCompanyAction: "Åtgärdsplan",
  fieldCompanyFollowUpPlan: "Uppföljning",
  fieldCompanyGuidelines1: "Ange vilka av följande riktlinjer och/eller standarder företaget arbetar i enlighet med:",
  fieldCompanyGuidelines2: "FNs vägledande principer företag och mänskliga rättigheter",
  fieldCompanyILO: "ILO's åtta kärnkonventioner",
  fieldCompanyISO26000: "ISO 26000",
  fieldCompanyMapping: "Kartläggning",
  fieldCompanyName: "Företagsnamn",
  fieldCompanyOECD: "OECDs riktlinjer för multinationella företag",
  fieldCompanyOtherGuidelines: "Andra policys/riktlinjer",
  fieldCompanyPolicy:
    "Företaget har uppförandekod/policy/riktlinjer för att hantera socialt ansvarstagande i leverantörskedjan, inklusive rutiner för att följa upp",
  fieldCompanyResponsibility:
    "Om företaget har ett ledningssystem för socialt ansvarstagande, vad av nedanstående ingår i arbetet?",
  fieldCompanyReviewed: "Denna är tredjepartsreviderad",
  fieldCompanyRisk: "Riskanalys",
  fieldCompanySustainability: "Har företaget en uppdaterad hållbarhetsredovisning?",
  fieldCompanyUN: "FN's Global Compact",
  fieldComponent: "Vara/Delkomponent",
  fieldConcentrationRange: "Koncentrationsintervall",
  fieldContact: "Kontaktperson",
  fieldCorporateIDNO: "Organisationsnummer",
  fieldCountry: "Land",
  fieldDUNS: "DUNS",
  fieldDocDataChangeDesc: "Ändring avser:",
  fieldDocDataCreated: "Skapad",
  fieldDocDataPublished: "Publicerad",
  fieldDocDataSaved: "Senast sparad",
  fieldDocDataSerialNumber: "Löpnummer",
  fieldDocDataStatus: "Status",
  fieldDocDataStatusComment: "Status kommentar",
  fieldDocDataSystemCode: "DUNS-nummer",
  fieldDocDataSystemID: "Ange dokumentidsystem",
  fieldDocDataValidFrom: "Giltig från",
  fieldDocDataValidTo: "Giltig till",
  fieldDocDataVersionNumber: "Version",
  fieldECNum: "EC Nr",
  fieldEMail: "E-post",
  fieldEndocrineDisruptor: "Hormonstörande",
  fieldGLN: "GLN",
  fieldHPhase: "H-fraser",
  fieldLead: "Bly (Pb) eller blyföreningar",
  fieldLimitationList: "Ämne på begränsningsbilagan",
  fieldLinkToLatestVersion: "Länk till senaste versionen",
  fieldLogo: "Logotyp",
  fieldLogoValue: "Klicka på knappen till höger för att ladda upp logotyp",
  fieldMaterial: "Material",
  fieldMaxWeight: "Maxvikt [%]",
  fieldMercury: "Kvicksilver (Hg) eller kvicksilverförening",
  fieldMinWeight: "Minvikt [%]",
  fieldNOECNumorCASNum: "om inte CAS eller EG är angivet, specificera varför",
  fieldOther: "Annat",
  fieldPBT: "PBT",
  fieldPhase: "Fas",
  fieldPhasingOutProperties: "Ämne med utfasningsegenskaper",
  fieldRoute: "Exponeringsväg/organ",
  fieldSubstance: "Ämne",
  fieldSysInfoDocumentBvdId: "ID",
  fieldSysInfoDocumentId: "Internt ID",
  fieldSysInfoLatestPdfFileAddress: "PDF-adress",
  fieldSysInfoName: "Ägare",
  fieldTelephone: "Telefon",
  fieldUserDisplayName: "Visningsnamn",
  fieldUserEmail: "Användarens e-post",
  fieldUserRole: "Ange användarroll",
  fieldVATNumber: "Momsnummer",
  fieldWebsite: "Webbplats",
  fielddocumentAttachment: "Ange URL till de dokument du hänvisningar till",
  fielddocumentReference: "Ange information om de dokurment du hänvisningar till",
  fieldsavedDate: "Företag senast sparad",
  fieldvPvB: "vPvB",
  filterShowLess: "Visa färre",
  filterShowMore: "Visa fler",
  footerText: "Samägt av",
  frontPageAdminButton: "Admin",
  frontPageDocumentButton: "Hantera eBVD",
  frontPageImportButton: "Importera eBVD",
  frontPageNewDocumentButton: "Skapa ny eBVD",
  hasRawMaterialOriginFossil: "För den här produkten, har det gjorts något uttag av orört fossilt material?",
  hasRequirementsOnSurroundingBuildingProducts: "Ställer varan särskilda krav på omgivande byggvaror?",
  hasStorageRequirements: "Ställer varan särskilda krav vid lagring?",
  hazardousWasteDelivered: "När den levererade varan blir avfall, klassas den då som farligt avfall?",
  hazardousWasteMounted: "Klassas den inbyggda varan som farligt avfall?",
  headerSearchPlaceholder: "Sök produkt, artikel ID eller GTIN",
  ifYesSpecify: "Om ja, specificera",
  iHaveReadIntegrityPolicy: "Jag har läst och godkänner eBVD:s",
  integrityPolicy: "integritetspolicy",
  importButton: "Importera",
  importRefetchListTooltip: "Ladda om listan",
  importingTooltip: "Importerar",
  importDownloadExcelTemplateTooltip: "Ladda ner importmall",
  importDocumentsTooltip: "Importera ebvd via fil",
  importUploadFile: "Ladda upp fil",
  importDragAndDrop: "Dra och släpp fil här, eller välj en",
  importDocumentsModalTitle: "Importera eBVD/er",
  importDocuments: "Importera eBVD/er",
  importFileBufferErrorMessage: "En fil måste läggas till",
  importViaFile: "Importera via fil",
  importViaFileInfoText:
    "Observera att systemet söker igenom importfilen för eventuella fel innan en import påbörjas, om ett felmeddelande visas i listan nedan så påbörjades aldrig importen och ingen data har lagts till i systemet. Vänligen korrigera filen enligt nedanstående felmeddelande och ladda sedan upp den på nytt.",
  indexPageTitle: "Startsida",
  indoorDays: "Dagar",
  indoorEmissionAdd: "Lägg till emission",
  indoorEmissionEdit: "Redigera emission",
  indoorEnvironmentElectricityFieldBlock: "Elektriska fält",
  indoorEnvironmentEmissioner: "Emissioner",
  indoorEnvironmentEmissionerTableTitle:
    "Varan avger vid avsedd användning följande emissioner: Information lämnas i enlighet med SS-EN 16516: 2017",
  indoorEnvironmentEnterYes: "Om ja, vilket",
  indoorEnvironmentMagneticFieldBlock: "Magnetiska fält",
  indoorEnvironmentMesauringMethod: "Mätmetod",
  indoorEnvironmentMoistureLevel: "Har varan ett kritiskt fukttillstånd?",
  indoorEnvironmentNoiseBlock: "Buller",
  indoorEnvironmentNotEmissionMeasure: "Varans emission ej uppmätt",
  indoorEnvironmentNotIntended: "Varan är ej avsedd för inomhusbruk",
  indoorEnvironmentNotProduceEmission: "Varan avger inga emissioner",
  indoorEnvironmentOtherInformation: "Övriga upplysningar",
  indoorEnvironmentProductElectricField: "Kan varan ge upphov till elektriska fält?",
  indoorEnvironmentProductFungusBlock: "Färger och lacker",
  indoorEnvironmentProductMagneticField: "Kan varan ge upphov till magnetiska fält?",
  indoorEnvironmentProductNoise: "Kan varan ge upphov till eget buller?",
  indoorEnvironmentProductResistantToFungus: "Varan är motståndskraftig mot svamp och alger vid användning i våtrum",
  indoorEnvironmentTableEmissionMeasuringInterval1: "Mätpunkt 1",
  indoorEnvironmentTableEmissionResult1: "Result 1",
  indoorEnvironmentTableEmissionType: "Emissionstyp",
  indoorEnvironmentTableMeasuringInterval2: "Mätpunkt 2",
  indoorEnvironmentTableMeasuringMethod1: "Mätmetod 1",
  indoorEnvironmentTableMeasuringMethod2: "Mätmetod 2",
  indoorEnvironmentTableResult2: " Resultat 2",
  indoorEnvironmentUnit: "Enhet",
  indoorEnvironmentValue: "Värde",
  indoorHours: "Timmar",
  indoorModalEmissionTitle: "Typ av emission",
  indoorModalMeasuringMethodStandardTitle1: "Mätmetod/standard 1",
  indoorModalMeasuringMethodStandardTitle2: "Mätmetod/standard 2",
  indoorModalMeasuringPoint1: "Mätpunkt 1",
  indoorModalMeasuringPoint2: "Mätmetod 2",
  indoorModalResult1: "Resultat 1",
  indoorModalResult2: "Resultat 2",
  indoorModalUnit1: "Enhet 1",
  indoorModalUnit2: "Enhet 2",
  indoorTimeIntervalMeasurement1: "Tidsintervall för emissionmätning 1",
  indoorTimeIntervalMeasurement2: "Tidsintervall för emissionmätning 2",
  indoorWeeks: "Veckor",
  languageEN: "Engelska",
  languageSV: "Svenska",
  latestVersiontext: "Senaste",
  linktoWebsite: "Till hemsidan",
  loadMoreBtnText: "Ladda fler",
  loggingCountry: "Land för avverkning",
  loggingLegal: "Trävirket har avverkats på ett lagligt sätt och intyg finns för detta",
  loginMessage: "Loggar in..",
  menuCopy: "Kopiera",
  menuCreateXML: "Skapa XML",
  menuDelete: "Ta bort",
  menuShowVersions: "Visa alla versioner",
  name: "Namn",
  newslistSectionTitle: "Systeminformation",
  newsTitle1: "Uppdatering av material som blockerat publicering",
  newsTitle2: "Förenklad hantering gällande digital överföring via API",
  newsTitle3: "Lansering av ny funktion",
  newsDesc1:
    "I vissa fall har material som lades till i tabellen för kemiskt innehåll i tidigare desktop-appen innehållit ämnesinformation såsom exempelvis CAS/EG-nummer. Detta har blockerat publicering av den eBVD där informationen var inlagd på webben. Dessa material syns nu som ämnen i tabellen för att möjliggöra redigering av all inlagd information samt publicering av berörd eBVD. Vänligen kontakta supporten för frågor eller mer information.",
  newsDesc2:
    "För att göra det lättare att göra rätt och ha koll på sin data har vi justerat styrningen av digital överföring via API. Data som är både ej konfidentiell och publicerad syns på samma sätt både i den öppna sökfunktionen och via API-kopplingen. Vi har med andra ord tagit bort möjligheten att välja att dela data utöver detta (konfidentiell/ej publicerad) via API-kopplingarna, för att undvika att data delas på ett sätt som inte är gemensamt förankrat i företaget. För mer information om vilka organisationer som hämtar publik data via vårt API, se fliken 'Digital överföring' på admin-sidan i inloggat läge på ebvd.org. Vänligen kontakta supporten för frågor eller mer information.",
  newsDesc3:
    "Den 28:e november lanserar vi en ny funktionalitet i eBVD-systemet, det går nu att exportera ut och importera in data genom en excel-fil. På första sidan efter att du loggat in finns nu en ny knapp ”Importera eBVD”  varifrån du når importfunktionen. Exportering av befintliga eBVDer nås genom ”Importera eBVD”, ”Bulkhantera” och slutligen ”Exportera Excel”. Mer information om de nya funktionerna kan du hitta på startsidan under ”Information och instruktioner om hur du använder eBVD-systemet, samt användarvillkor och integritetspolicy.” ",
  newsDescLink: "här.",
  of: "av",
  noOptionsText: "Ange sökord",
  noResultText: "Inga resultat hittades för ",
  noResultTextSearchPage: "Inga resultat för din sökning, försök med andra urval.",
  notWoodCITESAppendix: "Innehåller ej träslag eller ursprung i CITES appendix för hotade arter.",
  notWoodCITESAppendixVersion: "Vilken version av CITES har använts för kontrollen?",
  organizationListChoice: "Välj organisation att administrera",
  organizationListforImportChoice: "Välj organisation du vill importera åt",
  organizationListforTransactionChoice: "Välj organisation du vill se historik för",
  originRawMaterial: "Råvarans ursprung",
  phaseDelivery: "Leverans",
  phaseDeliveryOrMounted: "Leverans/inbyggd",
  phaseMounted: "Inbyggd",
  productContentTypeChemicalProduct: "Endast ämnesnivån visas som viktprocent av produktens totala vikt.",
  productContentTypeProductText1: "Komponenter du behöver för att kunna fylla i koncentration av TOTAL produkt",
  productContentTypeProductText2: "Material du ska kunna fylla i koncentration av KOMPONENT",
  productContentTypeProductText3: "Ämne du ska kunna fylla i koncentration av MATERIAL",
  radioButtonAdministrator: "Administratör",
  radioButtonAllInformation: "All information",
  radioButtonCannotread: "Kan inte läsa konfidentiell information",
  radioButtonCanread: "Kan läsa konfidentiell information",
  radioButtonChemicalProduct: "Kemisk produkt",
  radioButtonCoWorker: "Medarbetare",
  radioButtonCustomRole: "Anpassad roll",
  radioButtonMinimumInformation: "Minsta information",
  radioButtonNo: "Nej",
  radioButtonNotApplicable: "Ej relevant",
  radioButtonProduct: "Vara",
  radioButtonSelectedInformation: "Vald information",
  radioButtonSubcontractor: "Underleverantör",
  radioButtonUsageFifteenYears: "15 år",
  radioButtonUsageFiveYears: "5 år",
  radioButtonUsageGreaterthanFiftyYears: "> 50 år",
  radioButtonUsageNotAvailable: "Saknas",
  radioButtonUsageTenYears: "10 år",
  radioButtonUsageTwentyFiveYears: "25 år",
  radioButtonYes: "Ja",
  rawMaterialCityOfProductionTitle: "Tillverkningsstad",
  rawMaterialCityofRawMaterialsTitle: "Råvarustad",
  rawMaterialCommentTitle: "Kommentar",
  rawMaterialCountryOfProductionTitle: "Tillverkningsland",
  rawMaterialCountryofRawMaterialsTitle: "Råvaruland",
  rawMaterialModalArticleSubTitle: "Vara/Delkomponent",
  rawMaterialModalCityOfManufacturerTitle: "Stad för tillverkning/produktion",
  rawMaterialModalCityRawMaterialTitle: "Stad för råvaruutvinning",
  rawMaterialModalCommentTitle: "Kommentar",
  rawMaterialModalConfidentialTitle: "Konfidentiell",
  rawMaterialModalCountryOfManufacturerTitle: "Land för tillverkning/produktion",
  rawMaterialModalCountryRawMaterialTitle: "Land för råvaruutvinning",
  rawMaterialModalMaterialTitle: "Material",
  rawMaterialModalTitle: "Lägg till del/komponent",
  rawMaterialModalTransportTypeTitle: "Transporttyp",
  rawMaterialOriginFossilPart: "Om ja, ange hur stor del av det aktuella materialet (eller varan) ",
  rawMaterialPurchaseSystem:
    "Finns det för råvarorna underlag för tredjepartscertifierat system för kontroll av ursprung, råvarutvinning, tillverknings- eller återvinningsprocesser eller liknande (exempelvis BES 6001:2008, EMS-certifikat, USGBC Program)? Om ja, ange system/systemen",
  rawMaterialPurchaseSystemlabel: "Inköpssystem",
  rawMaterialTableComponentTitle: "Komponent",
  rawMaterialTableMaterialTitle: "Material",
  rawMaterialTableTitle: "Lägg in de ingående råvarorna i varan nedan",
  rawMaterialTransportTitle: "Transport",
  readMoreLink: "Läs mer",
  rename: "Ändra namn",
  recentlyViewed: "Tidigare besökta dokument",
  renewableMaterial: "Förnybart material",
  renewableMaterialShortPart: "Ange andel förnybart material i varan [%]",
  requirementsOnSurroundingBuildingProducts: "Om ja, specificera",
  restrictionsAndRecommendations:
    "Har leverantören restriktioner och/eller rekommendationer för återanvändning, material- eller energiåtervinning eller deponering?",
  reusable: "Är återanvändning möjlig för hela eller delar av varan när den byts ut/kasseras?",
  routeAbout: "Om eBVD webb",
  routeDigitalEpd: "Digitala EPD:er",
  routeDigitalEpdDetail: "Digital EPD detaljer",
  routeAdmin: "Administration",
  routeImport: "Importera",
  routeCreateAccount: "Skapa konto",
  CreateAccount: "Skapa konto",
  CreateAccountSubmit: "Skicka",
  routeDocuments: "Sök & hantera eBVD",
  routeHome: "Hem",
  routePublicSearch: "Sök eBVD",
  routeRegistration: "Registation",
  routeTransactions: "Transaktioner",
  saveButtonText: "Spara",
  searchBSAB96FilterTitle: "BSAB96",
  searchDocumentArticleIdentity: "Artikelidentitet: ",
  searchDocumentBK04: "BK04: ",
  searchDocumentBSAB96: "BSAB96: ",
  searchDocumentId: "Id: ",
  searchDocumentPublished: "Publicerad: ",
  searchDocumentCreated: "Skapad: ",
  searchDocumentUpdated: "Senast uppdaterad: ",
  searchDocumentStatus: "DOKUMENT STATUS",
  searchDocumentStatusCreated: "Skapat",
  searchDocumentStatusDeleted: "Raderad",
  searchDocumentStatusPublished: "Publicerad",
  searchDocumentSupplier: "Leverantör: ",
  searchDocumentValidFilter: "DOKUMENT GILTIGT",
  searchDocumentVersion: "Version ",
  searchIncludeOldVersion: "Inkludera gammal version",
  searchIncludeOldVersionTitle: "INKLUDERA GAMMAL VERSION",
  searcheBVDPageTitle: "Sök och hantera eBVD",
  searcheBVDTitle: "Sök eBVD",
  sectionArticleInformation: "Artikelinformation",
  sectionChemicalContent: "Kemiskt innehåll",
  sectionCompanyCertificationTitle: "Företagets certifiering",
  sectionCompanyInformation: "Företagsinformation",
  sectionCompanyManagementTitle: "Ledningssystem för socialt ansvarstagande",
  sectionCompanyPoliciesTitle: "Policys och riktlinjer",
  sectionCompanyTitle: "Företag",
  sectionConstructionPhase: "Byggskedet",
  sectionDemolition: "Rivning",
  sectionDistribution: "Distribution av färdig vara",
  sectionDocumentData: "Dokumentdata",
  sectionEnvironmentalImpact: "Miljöpåverkan",
  sectionIndoorEnvironment: "Innemiljö",
  sectionRawMaterials: "Råvaror",
  sectionSystemInformation: "Systeminformation",
  sectionTotalRecycledMaterial: "Totalt cirkulerat material i varan",
  sectionUsagePhase: "Bruksskedet",
  sectionWasteManagement: "Resurs- och avfallshantering",
  sections: "Sektioner",
  sessionTitleSubstanceProperties: "Ämnesegenskaper",
  sortFilterDocumentUpdatedTimeAsc: "Tidigast uppdaterad",
  sortFilterDocumentUpdatedTimeDesc: "Senast uppdaterad",
  sortFilterDocumentVersion: "Dokumentversion",
  sortFilterDocumentIdAsc: "eBVD ID A-Ö",
  sortFilterDocumentIdDesc: "eBVD ID Ö-A",
  sortFilterProductNameAsc: "Produktnamn A-Ö",
  sortFilterProductNameDesc: "Produktnamn Ö-A",
  sortFilterTitle: "Sortera efter",
  storageRequirements: "Om ja, specificera",
  supplierFilterChip: "Leverantör: ",
  supplierFilterTitle: "LEVERANTÖR",
  tempSearchCreateNew: "Skapa ny",
  tempSearchLabel: "Sök dokument",
  tempSearchOpen: "Öppna",
  tempSearchShowPDF: "Visa PDF",
  tempSupplierSearchLabel: "Sök leverantör",
  toastDeleted: "har raderats",
  toastPublish: " är nu publicerad",
  toastSaved: "har sparats",
  toastUnPublish: "har avpublicerats",
  toastUpdated: "Uppdaterad",
  toolBarCreatePDF: "Skapa PDF",
  toolBarNewVersion: "Ny version",
  toolBarPublish: "Publicera",
  toolBarSave: "Spara",
  toolBarUnPublish: "Avpublicera",
  toolTipDownloadPDF: "Ladda ner PDF",
  toolTipGoToDocument: "Gå till dokument",
  toolTipPublish: "Publicera",
  toolTipUnPublish: "Avpublicera",
  totalRecycleEditAttachment: "Redigering ingående återvunnet material",
  totalRecycleMateialTableRecycle: "Återanvänt material",
  totalRecycleMaterialArticle: "Ingår återanvänt och/eller återvunnet material i varan? Om ja, fyll i tabellen nedan",
  totalRecycleMaterialComment: "Kommentar",
  totalRecycleMaterialModalComment: "Kommentar",
  totalRecycleMaterialModalProportionWeight:
    "Ange den andel av varans totala vikt som det återvunna materialet utgör [% eller g]",
  totalRecycleMaterialModalRecycled: "Återanvänt material",
  totalRecycleMaterialModalSharePercentage: "Andel spill (från annans produktion)",
  totalRecycleMaterialModalUnit: "Enhet",
  totalRecycleMaterialNotPassedConsumer: "Andel spill (från egen produktion)",
  totalRecycleMaterialPassedConsumer: " Återvunnet material (behandlat)",
  totalRecycleMaterialTableMaterialHeading: "Material",
  totalRecycleMaterialTableProportionAfter: "Återvunnet material (behandlat)",
  totalRecycleMaterialTableProportionBefore: "Andel spill (från egen produktion)",
  totalRecycleMaterialTableProportionOfWaste: "Andel spill (från annans produktion)",
  totalRecycleMaterialTableTitle: "Om ja, ange hur stor del av det aktuella materialet (eller varan) som är återvunnet",
  totalRecycleMaterialWeight: "Vikt/Viktprocent",
  totalRecycledMaterialModalMaterialTitle: "Material",
  transactions: "Transaktioner",
  transactionCreated: "Skapad",
  transactionPageTitle: "Publicering/Avpublicering",
  transactionResultMessage: "Resultatmeddelande",
  transactionStatus: "Status",
  unsavedDocumentPromptMessage: "Du har osparade ändringar, är du säker på att du vill lämna?",
  unpublishDocumentTitle: "Avpublicera eBVD/eBVDs",
  unpublishDocumentsMessage: "Är du säker på att du vill avpublicera: ",
  usagePhaseComment: "Kommentar",
  usagePhaseEnergyLabelForProduct: "Finns energimärkning enligt energimärkningsdirektivet (2010/30/EU) för varan?",
  usagePhaseEnergySupply: "Ställer vara krav på energitillförsel för drift?",
  usagePhaseEnterLabelTitle: "Om ja ange märkning (G till A, A+, A++, A+++)",
  usagePhaseEnterLabelTitleTwo: "Om ja ange märkning (G till A)",
  usagePhaseOperationSpecify: "Om ja, specificera",
  usagePhaseOpertaionTitle: "Ställer varan krav på insatsvaror för drift och underhåll?",
  usagePhaseOtherInformation: "Övriga upplysningar",
  usagePhaseTechnicalServiceLife: "Uppskatta teknisk livslängd för varan, anses enligt alternativ a) eller b) nedan:",
  usagePhaseTechnicalServiceLifeEstimate: "a) Referenslivslängden uppskattas vara cirka",
  usagePhaseTechnicalServiceLiftEstimate: "b) Referenslivslängden uppskattas vara i intervallet [år]",
  usagePhaseYears: "år",
  userAccepted: "Accepterad",
  userCreated: "Skapad",
  userDetailOrganization: "Organisation",
  userDetailUser: "Användare",
  userEMail: "E-post",
  userFieldGroup: "Grupp",
  userInformationAccess: "Tillgång till information",
  userName: "Namn",
  userNotified: "Aviserad",
  userPhoneNo: "Telefonnummer",
  userRole: "Roll",
  userRoleAdmin: "Administratör",
  userRoleCreateNew: "Kan skapa nya",
  userRoleCreateNewVersion: "Kan skapa ny version",
  userRoleDelete: "Kan radera",
  userConfidentiality: "Sekretess",
  userRoleEdit: "Kan redigera",
  userRolePublish: "Kan publicera",
  userRoleRead: "Kan läsa",
  userRoleUnpublish: "Kan avpublicera",
  organizationSectionInformation: "Information",
  organizationSectionUser: "Användare",
  organizationSectionUserInvitation: "Användarinbjudan",
  organizationSectionDigitalTransfer: "Digital överföring",
  organizationSectionPublicAcces: "Åtkomst för allmänheten",
  digitalTransferOrganization: "Organisation",
  digitalTransferWebApiConsumer: "Web API-konsument",
  digitalTransferAddress: "Adress",
  digitalTransferHomePage: "Hemsida",
  digitalTransferReference: "Referens",
  digitalTransferPhoneNumber: "Telefonnummer",
  digitalTransferEMail: "E-post",
  fieldUserPassword: "Lösenord",
  roleCanEditOrganization: "Kan redigera organisation",
  userRoles: "Roller",
  userSent: "Skickad",
  userStatus: "Status",
  wasteManagementDeliveredTableTitle: "Lägg till avfallskod",
  wasteManagementDeliveredTableWastecode: "Avfallskod",
  wasteManagementMountedTableTitle: "Lägg till avfallskod",
  wasteManagementMountedTableWastecode: "Avfallskod",
  wasteMaterialComment: "Övriga upplysningar",
  wasteTreatmentTabMountedGroupBoxInfoText:
    "Om varans kemiska sammansättning är annan efter inbyggnad än vid leverans, och den inbyggda varan därmed får en annan avfallskod anges den här. Om den är oförändrad utelämnas nedanstående uppgifter.",
  welcomeSectionContent:
    "Genom en digital tjänst tillhandahåller eBVD-systemet ett verktyg där du som tillverkare av byggprodukter och byggmaterial enkelt kan registrera och hantera din digitala miljöinformation. Vad som ska registreras i en eBVD (elektronisk byggvarudeklaration) är överenskommet av hela byggbranschen. eBVD i Norden AB är ett dotterbolag till IVL Svenska Miljöinstitutet och Byggmaterialindustrierna.",
  welcomeSectionContentToHomepage: "Ytterligare information om eBVD och bakgrunden på systemet hittas på",
  welcomeSectionContentAbouteBVD:
    "Information och instruktioner om hur du använder eBVD-systemet, samt användarvillkor och integritetspolicy",
  welcomeSectionTitle: "Välkommen till eBVD!",
  welcomeSectionAdditionalInfo: "Ytterligare information om eBVD och bakgrunden på systemet hittas på ",
  welcomeSectionQuestionsAbouteBVD: "Har du frågor eller funderingar om eBVD - vänligen kontakta oss på",
  woodCertified: "Finns produkten att köpa certifierad?",
  woodCertifiedPart: "Hur stor andel är certifierad [%]",
  woodCertifiedSystem: "Vilket certifieringssystem har använts (exempelvis FSC, CSA, SFI med CoC, PEFC)",
  woodCertifiedSystemReference: "Referensnummer",
  woodLoggingCountry: "Ange avverkningsland för träråvaran, samt att nedanstående kriterier har uppfyllts.",
  woodenRawMaterial: "Träråvaror",
  userAdd: "Lägg till användare till din organisaton",
  allInformation: "All information",
  noConfidentialInformation: "Ingen konfidentiell information",
  minimumInformation: "Minsta information",
  selectedInformation: "Vald information",
  tooltipInviteUser: "Bjud in användare",
  tooltipEditUser: "Editera användare",
  tooltipDeleteUser: "Ta bort användare",
  tooltipReinviteUser: "Skicka inbjudan igen",
  tooltipDeleteUserInvitation: "Ta bort inbjudan",
  tooltipEditDigitalTransfer: "Editera digital överföring",
  tooltipBulkManagement: "Använd checkboxarna för att hantera flera dokument",
  registrationTitle: "Acceptera registrering",
  registrationSuccessTitle: "Registreringsförfrågan skickad",
  registrationSuccessTitle2: "Tack för din registrering i eBVD!",
  accountInvoiceInformation: "Fakturainformation",
  accountContactInformation: "Kontaktinformation",
  accountInvoiceCountry: "Fakturaland",
  accountInvoiceAddress: "Fakturaadress",
  accountPostCode: "Postnummer",
  accountInvoiceReference: "Fakturareferens",
  accountInvoiceEmail: "E-postfaktura",
  accountInternalOrganizationID: "Internt organisations-ID",
  accountVisitingAddress: "Besöksadress",
  accountReference: "Referens",
  accountEmail: "E-post",
  accountTurnover: "Omsättning",
  accountNumberOfBVDs: "Antal BVD:er",
  accountDirectMemberOfByggmaterialindustrierna: "Direct member of Byggmaterialindustrierna Member of",
  accountTheFlooringIndustry: "Golvindustrin",
  accountIKEMTheInnovationAndChemicalIndustries: "IKEM – Innovations- och kemisk industri",
  accountNordicGalvanizers: "Nordic Galvanizers",
  accountScandinavianCopperDevelopmentAssociationSCDA: "Scandinavian Copper Development Association SCDA",
  accountTheForestIndustries: "Skogsindustrierna",
  accountTheSteelConstructionInstitute: "Stålbyggnadsinstitutet",
  accountSwedisol: "Swedisol",
  accountSwedishVentilation: "Svensk Ventilation",
  accountSwedishConcrete: "Svensk Betong",
  accountSwedenMiningIndustry: "Sveriges Gruvindustri",
  accountSwedenFärgfabrikantersAssociation: "Sweden's Färgfabrikanters Association",
  accountSwedenFogAndGlueSuppliers: "Sveriges Dim- och limleverantörer",
  accountTheWoodAndFurnitureCompanies: "Trä- och möbelföretagen",
  accountTheSwedishRefrigerationAndHeatPumpAssociation: "Svenska Kyl- och Värmepumpsföreningen",
  accountSwedishByggplåt: "Swedish Byggplåt",
  register: "Registrera",
  registrationConfirmationTitle: "Du är nu registrerad!",
  registrationConfirmationSubTitle: "Logga in med din e-postadress",
  registrationConfirmationMessage:
    "Första gången du loggar in på verktyget klickar du på den andra lösenordslänken och följer instruktionerna för att få ditt lösenord registrerat. Om du redan har loggat in så klicka på eBVD loggan uppe till vänster för att komma till startsidan.",
  documentAlert:
    "För att ladda ner en PDF av en eBVD klicka på den i listan. För att se mer information eller göra ändringar, vänligen logga in.",
  manageeBVD: "Sök & hantera eBVD",
  digitalEPD: "Digitala EPD:er",
  searcheBVD: "Sök eBVD",
  FAQ: "FAQ",
  userManual: "Användarmanual",
  instructions: "Instruktioner",
  instructionsImportTemplate: "Instruktion importfunktion",
  privacyPolicy: "Integritetspolicy",
  userTerms: "Användarvillkor",
  fees: "Avgifter",
  registrationSuccessText1:
    "Efter att vi tagit emot din registrering så skickar vi en bekräftelse per mail med ett bifogat licensavtal, samt information om gällande avgifter. Ta del av vilken avgift som gäller för ert företag. Skriv under licensavtalet (firma¬tecknare eller annan behörig person) och returnera ett inscannat, signerat avtal till  ",
  registrationSuccessText2:
    "När vi tagit emot det underskrivna avtalet aktiverar vi dig som användare och skickar information om inloggningsuppgifter. Sedan kan du börja registrera och administrera dina eBVD:er.",
  registrationSuccessText3:
    "Behöver du mer information kring hur det går till att ansluta dig till eBVD, kontakta vår support: ",
  toHomePage: "Tillbaka till startsidan",
  routeRegistrationSuccess: "Registreringsförfrågan skickad",
  headerLinkCreateAccount: "Skapa konto",
  toastDTDeleted: "Borttagen",
  toastDTSaved: "Sparad",
  abouteBVDPageTitle: "Om eBVD webb",
  DigitalEpdTitle: "Digital EPD",
  cookieMessage:
    "Vi använder cookies (enhetsidentifierare) för att anpassa vårt innehåll till dig, erbjuda automatisk översättning och analysera vår trafik. Du kan läsa mer om hur vi skyddar din integritet i vår integritetspolicy.",
  cookieLink: "cookieLink",
  cookiePolicyLinkText: "Integritetspolicy",
  cookieNecessaryOptionText: "Nödvändiga",
  cookiePreferenceOptionText: "Inställningar",
  cookieStatisticsOptionText: "Statistik",
  cookieMarketingOptionText: "cookieMarketingOptionText",
  cookieManageCookieButton: "Hantera mina kakor",
  cookieSaveCookiePref: "Spara och stäng",
  cookieAcceptButton: "Jag godkänner",
  cookieDeclineButton: "cookieDeclineButton",
  articleDeclarationOfPerformanceNumberSubtitle:
    "Finns en prestandadeklaration, enligt byggproduktförordningen, framtagen för varan?",
  usagePhaseCommentError: "Ange uppskattad teknisk livslängd för varan, eller en kommentar",
  errorMinimumOneMessage: "Minst 1 objekt i listan ovan",
  EpdFilterName: "Filtrera på produkt namn",
  EpdFilterOwner: "Filtrera på EPD ägare",
  EpdCreateNewArticle: "Skapa ny artikel",
  EpdCreateNewResource: "Skapa ny resurs",
  EpdGeneralInformation: "Allmän information",
  EpdUploadPdf: "Ladda upp PDF",
  EpdUnit: "Unit / Produkt typ",
  EpdEnvironmentalIndicators: "Miljöindikatorer",
  EpdAtricleSystem: "Artikel system",
  EpdDownloadExcelTemplate: "Ladda ned Excel mall",
  EpdClose: "Ångra",
  EpdUpload: "Ladda upp artikel",
  EpdUploadExcelHelperText: "Ladda upp en Excel fil",
  EpdChooseFileType: "Välj filtyp",
  EpdSaveChanges: "Spara ändringar",
  EpdUseCalculatedValue: "Använd beräknat värde",
  EpdEnvironmentalInfo:
    "GWP-GHG-värdet beräknas utifrån det faktum att deras biogena kol som rapporterats i GWP-biogenic är lika med kollagrade siffror ovan.",
  EpdYes: "Ja",
  EpdNo: "Nej",
  EpdUploading: "Laddar upp artikel...",
  EpdRowsPerPage: "Rader per sida",
  EpdChooseFileTypeLabel: "Välj filtyp",
  EpdName: "Namn",
  EpdCreatingResource: "Skapar",
  EpdCreateResource: "Skapa resurs",
  EpdTitle: "EPD:er",
  EpdUploadFile: "Ladda upp en ",
  EpdAlert: "För att lägga till en resurs vänligen logga in.",
  EpdAlertArticle: "För att lägga till en artikel vänligen logga in.",
  EpdAlertGeneralInformation: "För att redigera och ladda upp PDF vänligen logga in.",
  EpdAlertToEdit: "För att redigera vänligen logga in.",
};
